import React, { useEffect, useState, useRef } from "react";
import {
  TextField,
  FormControl,
  Grid,
  Typography,
  Button,
  StepLabel,
  Step,
  Stepper,
  Paper,
  Container,
  InputLabel,
  NativeSelect,
  InputAdornment,
  Select,
  MenuItem,
  Box,
  Divider,
} from "@mui/material";
import * as htmlToImage from "html-to-image";
import html2canvas from "html2canvas";
import axios from "../utils/axiosConfig";
import CoinPayment from "../components/CoinPayment";
import { loginCheck, redirectToLogin } from "../utils/loginCheck";
import { TaskBar } from "../components/TaskBar";
import Bitcoin from "../assets/images/bitcoin.png";
import Ethereum from "../assets/images/eth.png";
import Trc20Usdt from "../assets/images/usdt.png";
import Litecoin from "../assets/images/litecoin.png";
import Dogecoin from "../assets/images/dogecoin.png";
import Checked from "../assets/images/checked.png";
import { Download, Share } from "@mui/icons-material";
import { getSymbol } from "../utils";

const Icons = {
  bitcoin: Bitcoin,
  trc20_usdt: Trc20Usdt,
  ethereum: Ethereum,
  litecoin: Litecoin,
  dogecoin: Dogecoin,
  erc20_usdt: Trc20Usdt,
};

export default function Checkout() {
  const [authenticated, setAuthenticated] = useState(
    sessionStorage.getItem("username")
  );

  useEffect(() => {
    const loggedInUser = sessionStorage.getItem("username");
    if (loggedInUser) {
      setAuthenticated(loggedInUser);
    }
  }, []);

  const [activeStep, setActiveStep] = useState(0);

  const [status, setStatus] = useState("");

  const [payInfo, setPayInfo] = useState({
    network: "",
    usdAmount: "",
    cryptoAmount: 0,
    remark: "",
  });

  useEffect(() => {
    const coinPayData = sessionStorage.getItem("coinPayData");
    const curStep = sessionStorage.getItem("coinPayActiveStep");
    if (coinPayData && curStep) {
      const parsedData = JSON.parse(coinPayData);
      setActiveStep(JSON.parse(curStep));
      setPayInfo(parsedData.payInfo);
    }
  }, []);

  const AgentCodePage = () => {
    const [agentCode, setAgentCode] = useState("");
    const [error, setError] = useState(false);
    const [helperMsg, setHelperMsg] = useState("");
    const [checkCode, setCheckCode] = useState(false);
    const [agentCodes, setAgentCodes] = useState([{ agentcode: "" }]);
    const [usdAmount, setUsdAmount] = useState(payInfo.usdAmount);
    const [remark, setRemark] = useState("");

    const handleClick = (keyboard: string) => {
      setError(false)
      if (parseInt(usdAmount) == 0) {
        setUsdAmount("");
      }
      setUsdAmount(usdAmount.toString() + keyboard);
    };

    useEffect(() => {
      const getAgentCodes = async () => {
        try {
          await axios
            .get(`/api/getAgentCodes`)
            .then((response) => {
              setAgentCodes(response.data);
            })
            .catch((error) => {
              console.error("Error fetching data:", error);
            });
        } catch (error) {
          console.error("Error fetching agent codes:");
          return false;
        }
      };
      getAgentCodes();
    }, []);

    const handleNext = async () => {
      if (agentCode === "") {
        setError(true);
        setHelperMsg("Incorrect Agent Code. Please contact your agent.");
        return false;
      }
      console.log("debug usd", parseFloat(usdAmount))
      if (!(parseFloat(usdAmount) > 0)) {
        setError(true);
        setHelperMsg("Invalid Amount. Please input correct input.");
        return false;
      }

      try {
        await axios
          .get(`/api/checkAgentCode?agentCode=${agentCode}`)
          .then((response) => {
            setCheckCode(response.data);
            sessionStorage.setItem("usdAmount", usdAmount.toString());
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      } catch (error) {
        console.error("Error fetching unconfirmed transactions:");
        return false;
      }
    };

    useEffect(() => {
      if (checkCode) {
        sessionStorage.setItem("coinPayAgentCode", agentCode);
        setPayInfo({
          network: "",
          usdAmount: "0",
          cryptoAmount: 0,
          remark: remark,
        });
        setActiveStep(1);
      }
    }, [checkCode, agentCode]);

    return (
      <React.Fragment>
        <TaskBar name="Make Payment" viewHistory />
        <Typography style={{ marginTop: "45px" }}>Select Agent Code</Typography>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={agentCode}
          style={{ width: "200px" }}
          placeholder="Select a Agent Code"
          onChange={(e) => {
            setAgentCode(e.target.value);
            setError(false);
          }}
        >
          {agentCodes.map((code) => {
            return (
              <MenuItem value={code.agentcode} key={code.agentcode}>
                {code.agentcode}
              </MenuItem>
            );
          })}
        </Select>
        <Typography>Note or Remarks</Typography>
        <TextField
          inputProps={{
            style: {
              height: "2.4em",
              boxSizing: "border-box",
              width: "200px",
            },
          }}
          placeholder=""
          onChange={(e) => setRemark(e.target.value)}
        ></TextField>
        <Typography fontSize={50} fontWeight={700} style={{ lineHeight: 1 }}>
          ${usdAmount == "" ? "0" : usdAmount}
        </Typography>
        <Typography
          style={{ color: "red", display: `${error ? "block" : "none"}` }}
        >
          {helperMsg}
        </Typography>

        <Box className="fixed bottom-0 fixed-center w-full ">
          <div className="grid-container">
            <div className="grid-item" onClick={() => handleClick("1")}>
              1
            </div>
            <div className="grid-item" onClick={() => handleClick("2")}>
              2
            </div>
            <div className="grid-item" onClick={() => handleClick("3")}>
              3
            </div>
            <div className="grid-item" onClick={() => handleClick("4")}>
              4
            </div>
            <div className="grid-item" onClick={() => handleClick("5")}>
              5
            </div>
            <div className="grid-item" onClick={() => handleClick("6")}>
              6
            </div>
            <div className="grid-item" onClick={() => handleClick("7")}>
              7
            </div>
            <div className="grid-item" onClick={() => handleClick("8")}>
              8
            </div>
            <div className="grid-item" onClick={() => handleClick("9")}>
              9
            </div>
            <div className="grid-item" onClick={() => handleClick(".")}>
              .
            </div>
            <div className="grid-item" onClick={() => handleClick("0")}>
              0
            </div>
            <div className="grid-item" onClick={() => setUsdAmount("")}>
              &lsaquo;
            </div>
          </div>
        </Box>
        <Box className="fixed bottom-2 fixed-center w-full p-4">
          <Button
            variant="contained"
            className="next-button-with-calculator"
            onClick={handleNext}
          >
            Next
          </Button>
        </Box>
      </React.Fragment>
    );
  };

  const ProductPage = () => {
    const [cryptoAmount, setCryptoAmount] = useState(payInfo.cryptoAmount);
    const usdAmount = sessionStorage.getItem("usdAmount") ?? "0";
    const [network, setNetwork] = useState("bitcoin");
    const [coinPrice, setCoinPrice] = useState("1");
    const [agentCode, setAgentCode] = useState("");
    const [networks, setNetworks] = useState([]);
    const loggedInUser = sessionStorage.getItem("username");

    const defaultCurrencies = [
      {
        value: "bitcoin",
        icon: Bitcoin,
      },
      // {
      //   value: "ethereum",
      //   icon: ethereumIcon,
      // },
      // {
      //   value: "dogecoin",
      //   icon: Dogecoin,
      // },
      // {
      //   value: "trc20_usdt",
      //   icon: Trc20Usdt,
      // },

      // {
      //   value: "litecoin",
      //   icon: Litecoin,
      // },
      // {
      //   value: "erc20_usdt",
      //   icon: erc20_usdtIcon,
      // },
    ];
    const [currencies, setCurrencies] = useState(defaultCurrencies);
    const agentCodeData = sessionStorage.getItem("coinPayAgentCode");
    const getCoinPrice = async (_symbol = "BTC") => {
      await axios
        .get(
          `https://min-api.cryptocompare.com/data/price?fsym=${_symbol}&tsyms=USD&api_key=97f290dcff3451685c5f0b810323e7f144ff149f1689fd226675151f6e8bfa1a`
        )
        .then((response: any) => {
          const price = response.data["USD"];
          setCoinPrice(price);
          return price;
        })
        .catch((error: any) => {
          console.error("Error fetching Bitcoin price:", error);
        });
    };

    const getNetworks = async (agentCodeData: any) => {
      await axios
        .get(`/api/getNetworks?agentCode=${agentCodeData}&customerName=${loggedInUser}`)
        .then((response) => {
          const resNetworks = response.data.networks;
          console.log('debug resNets::', resNetworks)
          setNetworks(resNetworks);
        })
        .catch((error) => {
          console.error("Error fetching Networks data:", error);
        });
    };

    const handleBack = () => {
      setActiveStep(0);
      sessionStorage.setItem("coinPayActiveStep", "1");
      sessionStorage.setItem("coinPayData", "");
      sessionStorage.setItem("coinPayWalletAddr", "");
      sessionStorage.setItem("coinPayUniqueCode", "");
      sessionStorage.setItem("paymentStatus", "");
      setPayInfo({
        network: "",
        usdAmount: "0",
        cryptoAmount: 0,
        remark: "",
      });
    };

    const getCryptoAmount = (_network: string) => {
      let _cryptoAmount = parseFloat(usdAmount);
      switch (_network) {
        case "bitcoin":
          getCoinPrice();
          _cryptoAmount = parseFloat(usdAmount) / parseFloat(coinPrice);
          break;
        case "ethereum":
          getCoinPrice("ETH");
          _cryptoAmount = parseFloat(usdAmount) / parseFloat(coinPrice);
          break;
        case "dogecoin":
          getCoinPrice("doge");
          _cryptoAmount = parseFloat(usdAmount) / parseFloat(coinPrice);
          break;
        case "litecoin":
          getCoinPrice("ltc");
          _cryptoAmount = parseFloat(usdAmount) / parseFloat(coinPrice);
          break;
      }
      setCryptoAmount(_cryptoAmount);

      return _cryptoAmount;
    };

    if (coinPrice == "") getCoinPrice();

    useEffect(() => {
      if (!isNaN(parseFloat(usdAmount))) {
        const coinPayData = {
          payInfo: {
            network: network,
            usdAmount: usdAmount,
            cryptoAmount: getCryptoAmount(network),
            remark: payInfo.remark,
          },
        };
        // sessionStorage.setItem(
        //   "coinPayActiveStep",
        //   JSON.stringify(activeStep + 1)
        // );
        sessionStorage.setItem("coinPayData", JSON.stringify(coinPayData));
      }
      const coinPayData = sessionStorage.getItem("coinPayData");
      if (!coinPayData) {
        getStepContent(0);
      }
      const paymentStatus = sessionStorage.getItem("paymentStatus");
      if (paymentStatus) setStatus(paymentStatus);
    }, [network, coinPrice]);

    useEffect(() => {
      if (agentCodeData) {
        setAgentCode(agentCodeData);
        getNetworks(agentCodeData);
      }
    }, [agentCodeData]);

    useEffect(() => {
      if (networks && networks.length > 0) {
        const currenciesFromNets = networks.map((net) => ({
          value: net,
          icon: Icons[net],
        }));
        setCurrencies(currenciesFromNets);
      }
    }, [networks]);
    console.log("debug agent::", networks, currencies);

    const getIcon = () => {
      const currency = currencies.filter((c) => c.value === network);
      return currency[0].icon;
    };

    return (
      <React.Fragment>
        <TaskBar name="Make a Payment" handleBack={() => handleBack()} />
        <Box className="row mt-4">
          <Typography>Selected Coin</Typography>
          <Select
            sx={{
              width: 160,
              height: 45,
              boxShadow: "none",
              ".MuiOutlinedInput-notchedOutline": { border: 0 },
              ".MuiBox-root": { alignItems: "center" },
            }}
            displayEmpty
            value={network}
            id="select-coin"
            labelId="select-coin"
            onChange={(e) => setNetwork(e.target.value)}
            renderValue={(value) => {
              return (
                <Box sx={{ display: "flex", gap: 1 }}>
                  <img src={getIcon()} width={32} height={32} />
                  {value}
                </Box>
              );
            }}
          >
            {currencies.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                <img src={option.icon} width={32} height={32} />
                &nbsp;&nbsp;&nbsp;{option.value}
              </MenuItem>
            ))}
          </Select>
        </Box>
        {/* <Box className="row ">
          <Typography>Note or Remarks</Typography>
          <TextField
            inputProps={{
              style: {
                padding: "8px",
              },
            }}
            placeholder=""
            onChange={(e) => setRemark(e.target.value)}
          ></TextField>
        </Box> */}
        <Box className="row ">
          <Typography>Payment Value</Typography>
          <Typography>{cryptoAmount.toFixed(8)}</Typography>
        </Box>
        <Typography
          style={{ marginTop: "32px" }}
          fontWeight={700}
          fontSize={20}
        >
          Scan QR or Copy Address
        </Typography>
        {cryptoAmount > 0 && (
          <CoinPayment
            network={network}
            amount={cryptoAmount}
            setActiveStep={setActiveStep}
            setStatus={setStatus}
          />
        )}
        <Grid container spacing={3}></Grid>
      </React.Fragment>
    );
  };

  const PaymentPage = () => {
    const agentCode = sessionStorage.getItem("coinPayAgentCode");
    const ref = useRef<HTMLElement | null>(null);
    const coinPayData = sessionStorage.getItem("coinPayData");
    const parsedData = JSON.parse(coinPayData);
    // useEffect(() => {
    //   const coinPayData = sessionStorage.getItem("coinPayData");
    //   const curStep = sessionStorage.getItem("coinPayActiveStep");
    //   if (coinPayData && curStep) {
    //     const parsedData = JSON.parse(coinPayData);
    //     setActiveStep(JSON.parse(curStep));
    //     setPayInfo(parsedData.payInfo);
    //   }
    // }, []);
    const handleDownload = () => {
      const { cropPositionTop, cropPositionLeft, cropWidth, cropHeigth } = {
        cropPositionTop: 0,
        cropPositionLeft: 0,
        cropWidth: 800,
        cropHeigth: 1200,
      };

      html2canvas(ref.current).then((canvas) => {
        let croppedCanvas = document.createElement("canvas");
        let croppedCanvasContext = croppedCanvas.getContext("2d");

        croppedCanvas.width = cropWidth;
        croppedCanvas.height = cropHeigth;

        croppedCanvasContext?.drawImage(
          canvas,
          cropPositionLeft,
          cropPositionTop
        );

        const a = document.createElement("a");
        a.href = croppedCanvas.toDataURL();
        a.download = "coinpay-download.png";
        a.click();
      });
    };

    const takeScreenShot = async (node) => {
      const dataURI = await htmlToImage.toJpeg(node);
      return dataURI;
    };

    const createFileName = (extension = "", ...names) => {
      if (!extension) {
        return "";
      }

      return `${names.join("")}.${extension}`;
    };

    const download = (image, { name = "img", extension = "jpg" } = {}) => {
      const a = document.createElement("a");
      a.href = image;
      a.download = createFileName(extension, name);
      a.click();
    };

    const downloadScreenshot = () => takeScreenShot(ref.current).then(download);

    const usdAmount = sessionStorage.getItem("usdAmount");

    const handleBack = () => {
      // if (status === "pending") {
      sessionStorage.setItem("coinPayActiveStep", "0");
      sessionStorage.setItem("coinPayData", "");
      sessionStorage.setItem("transactionId", "");
      sessionStorage.setItem("coinPayWalletAddr", "");
      sessionStorage.setItem("coinPayUniqueCode", "");
      sessionStorage.setItem("paymentStatus", "");
      // }

      setActiveStep(0);
    };

    useEffect(() => {
      const coinPayData = sessionStorage.getItem("coinPayData");
      if (!coinPayData) {
        getStepContent(0);
      }
      const paymentStatus = sessionStorage.getItem("paymentStatus");
      if (paymentStatus) setStatus(paymentStatus);
    }, []);

    return (
      <React.Fragment>
        {/* <p> Product: {payInfo.product}</p> */}

        <Box ref={ref} style={{ paddingBottom: "50px", background: "white" }}>
          <TaskBar name="Payment" handleBack={() => handleBack()} />
          <Box className="row text-color mt-3 p-2 mb-1">
            <Typography>Agent</Typography>
            <Typography>{agentCode}</Typography>
          </Box>
          <Divider />
          <Box className="row mt-1 p-2">
            <Typography>Total</Typography>
            <Box className="text-right">
              <Typography>${parsedData.payInfo.usdAmount}</Typography>
              <Typography>
                {parsedData.payInfo.cryptoAmount.toFixed(8)}{" "}
                {getSymbol(parsedData.payInfo.network)}
              </Typography>
            </Box>
          </Box>

          <CoinPayment
            network={parsedData.payInfo.network}
            amount={parsedData.payInfo.cryptoAmount}
            setActiveStep={setActiveStep}
            setStatus={setStatus}
          />
        </Box>

        {/* <Box className="flex justify-evenly mt-12">
          <Button
            variant="outlined"
            onClick={downloadScreenshot}
            startIcon={<Download />}
            className="download-button"
          >
            Download
          </Button>
          <Button
            variant="outlined"
            startIcon={<Share />}
            className="download-button"
          >
            Share
          </Button>
        </Box> */}

        <Box className="fixed bottom-6 fixed-center w-full p-4">
          <Button
            variant="contained"
            className="login-button"
            onClick={handleBack}
            sx={{ mt: 3, ml: 1 }}
          >
            {status !== "pending" ? (
              <>Back</>
            ) : (
              <>Proceed to another transaction</>
            )}
          </Button>
        </Box>
      </React.Fragment>
    );
  };

  const ReceivedPage = () => {
    const agentCode = sessionStorage.getItem("coinPayAgentCode");
    const inputRef = useRef<HTMLInputElement | null>(null);
    const [uniqueCode, setUniqueCode] = useState("");
    const sucessRef = useRef<HTMLElement | null>(null);
    const coinPayData = sessionStorage.getItem("coinPayData");
    const parsedData = JSON.parse(coinPayData);
    const copyToClipboard = () => {
      navigator.clipboard.writeText(uniqueCode);
    };

    const handleDownload = () => {
      const { cropPositionTop, cropPositionLeft, cropWidth, cropHeigth } = {
        cropPositionTop: 0,
        cropPositionLeft: 0,
        cropWidth: 800,
        cropHeigth: 1200,
      };

      html2canvas(sucessRef.current).then((canvas) => {
        let croppedCanvas = document.createElement("canvas");
        let croppedCanvasContext = croppedCanvas.getContext("2d");

        croppedCanvas.width = cropWidth;
        croppedCanvas.height = cropHeigth;

        croppedCanvasContext?.drawImage(
          canvas,
          cropPositionLeft,
          cropPositionTop
        );

        const a = document.createElement("a");
        a.href = croppedCanvas.toDataURL();
        a.download = "coinpay-download.png";
        a.click();
      });
    };

    const takeScreenShot = async (node) => {
      const dataURI = await htmlToImage.toJpeg(node);
      return dataURI;
    };

    const createFileName = (extension = "", ...names) => {
      if (!extension) {
        return "";
      }

      return `${names.join("")}.${extension}`;
    };

    const download = (image, { name = "img", extension = "jpg" } = {}) => {
      const a = document.createElement("a");
      a.href = image;
      a.download = createFileName(extension, name);
      a.click();
    };

    const downloadScreenshot = () =>
      takeScreenShot(sucessRef.current).then(download);

    const handleProceed = () => {
      sessionStorage.setItem("coinPayActiveStep", "0");
      sessionStorage.setItem("coinPayData", "");
      sessionStorage.setItem("transactionId", "");
      sessionStorage.setItem("coinPayWalletAddr", "");
      sessionStorage.setItem("coinPayUniqueCode", "");
      sessionStorage.setItem("paymentStatus", "");
      setActiveStep(0);
    };

    useEffect(() => {
      const uniqueCode = sessionStorage.getItem("coinPayUniqueCode");
      if (!uniqueCode) {
        sessionStorage.setItem("coinPayActiveStep", "1");
        sessionStorage.setItem("coinPayData", "");
        sessionStorage.setItem("coinPayWalletAddr", "");
        sessionStorage.setItem("coinPayUniqueCode", "");
        sessionStorage.setItem("paymentStatus", "");
        setPayInfo({
          network: "",
          usdAmount: "0",
          cryptoAmount: 0,
          remark: "",
        });
        setActiveStep(0);
      } else {
        setUniqueCode(uniqueCode);
      }
    }, []);

    return (
      <React.Fragment>
        <Box ref={sucessRef} style={{ background: "white" }}>
          <TaskBar name="Payment" handleBack={() => setActiveStep(1)} />

          <Grid container mt={2} spacing={2}>
            <Grid item xs={12}>
              <img src={Checked} style={{ margin: "auto" }} />
              <Typography fontWeight={700} fontSize={26} mt={4}>
                Payment Successful
              </Typography>
              <Typography fontWeight={500} className="text-color">
                Thank you for your payment!
              </Typography>
              <Typography
                fontWeight={500}
                className="text-color"
                fontSize={20}
                mt={2}
              >
                Confirmation Code
              </Typography>
              <div className="receiver-box flex items-center space-x-2">
                <Box
                  className="flex w-full mb-4"
                  style={{
                    background: "#efefef",
                    borderRadius: "8px",
                    height: "45px",
                    padding: "8px",
                    position: "relative",
                  }}
                >
                  <Typography ref={inputRef} className="text-color pt-1 pl-2">
                    {uniqueCode}
                  </Typography>
                  <Button
                    onClick={copyToClipboard}
                    sx={{
                      ml: 1,
                      position: "absolute",
                      borderRadius: "8px!important",
                      right: "8px",
                      bottom: "6px",
                      background: "white!important",
                      border: "1px solid #CFD6E4",
                      textTransform: "none",
                    }}
                  >
                    Copy
                  </Button>
                </Box>
              </div>
            </Grid>
            <Grid item xs={12}>
              <Typography fontWeight={700} fontSize={20} className="text-color">
                Agent Code
              </Typography>
              <Typography fontSize={26}>{agentCode}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography fontWeight={700} fontSize={20} className="text-color">
                Total Paid Amount
              </Typography>
              <Typography fontSize={26}>
                {parsedData.payInfo.cryptoAmount.toFixed(6)}{" "}
                {getSymbol(parsedData.payInfo.network)}
              </Typography>
            </Grid>
            {/* <Grid item xs={12}>
              <Box className="flex justify-evenly mt-6">
                <Button
                  variant="outlined"
                  onClick={downloadScreenshot}
                  startIcon={<Download />}
                  className="download-button"
                >
                  Download
                </Button>
                <Button
                  variant="outlined"
                  startIcon={<Share />}
                  className="download-button"
                >
                  Share
                </Button>
              </Box>
            </Grid> */}
            <Grid item xs={12}>
              <Box className="fixed bottom-4 fixed-center w-full p-4">
                <Button
                  variant="contained"
                  className="login-button"
                  onClick={handleProceed}
                  sx={{ mt: 3 }}
                >
                  Proceed to another transaction
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </React.Fragment>
    );
  };

  function getStepContent(step: any) {
    switch (step) {
      case 0:
        return <AgentCodePage />;
      case 1:
        return <ProductPage />;
      case 2:
        return <PaymentPage />;
      case 3:
        return <ReceivedPage />;
      default:
        throw new Error("Unknown step");
    }
  }

  if (!authenticated) {
    redirectToLogin();
  } else {
    return (
      <React.Fragment>
        <Container
          component="main"
          maxWidth="sm"
          className="relative"
          sx={{ mb: 4 }}
          style={{ borderRadius: "10px", height: "100%" }}
        >
          {getStepContent(activeStep)}
        </Container>
      </React.Fragment>
    );
  }
}
